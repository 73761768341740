import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { CommonService } from 'app/_services/common.services';
import { DatePipe } from '@angular/common';

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: any={};
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    token: any='';
    user: User;
  credentials: any={};
  mapdatatoshow: any;
  ro_overviewdata: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private httpClient: HttpClient,
        private _viewContainerRef: ViewContainerRef,
        private userservice:UserService,
        private cs:CommonService,
        private datePipe: DatePipe,
    )
    {
      this.cs.creds.subscribe((data:any) =>{
        if(data.username && data.password){
          this.credentials = data
        }
      })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
      //   this.userservice.user$
      // .pipe(takeUntil(this._unsubscribeAll))
      // .subscribe((user: User) => {
      //     this.user = user;

      //     // Mark for check
      //     this._changeDetectorRef.markForCheck();
      // });
      //   // Subscribe to notification changes
      //   this._notificationsService.notifications$
      //       .pipe(takeUntil(this._unsubscribeAll))
      //       .subscribe((notifications: Notification[]) => {

      //           // Load the notifications
      //           this.notifications = notifications;

      //           // Calculate the unread count
      //           this._calculateUnreadCount();
      //           this.call90sec();
      //           // Mark for check
      //           this._changeDetectorRef.markForCheck();
      //       });
    }

    call90sec(){
        // if(!this.stoploading){
        this.loginthignsboard()
        setTimeout(() => {
          this.call90sec()
        }, 30000);
    // }
      }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void
    {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void
    {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications.data && this.notifications.data.length )
        {
            count = this.notifications.data.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;
    }

     loginthignsboard(){
        const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders()
          .set('osd-xsrf', 'true')
          var kibanaurl=''
          const redirect_uri= "https://iomldevtb.mydigitalpetro.com/api/auth/login"
         
        const URL = redirect_uri
        // const creds = this.credentials
        const creds = this.cs.credentials
                // const creds = {username: "tenant@thingsboard.org", password: "digitalpetro"}
        this.httpClient.post(URL,creds,{ headers: httpOptions }).subscribe((data: any) => {
            console.log("Response tb", data)
            if(data && data.token){
              this.token = data.token
             this.get_ro_overview_data()
            }
            else{
            //   this.toast.sToast('error', "Could not fetch data")
            }
          }, error =>{
            console.log("error", error)
          })
      }
      

    getopenalertss(){   
        // this.loadingapi=true 
        // const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders({
          'X-Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        });
      
        // const redirect_uri= `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?searchStatus=ACTIVE&pageSize=100&page=0&sortOrder=DESC`
        var redirect_uri= `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?searchStatus=ANY&pageSize=100&page=0&sortProperty=createdTime&sortOrder=DESC`
        if(this.user.email == 'tenant1@rakaz.com'){
          redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?searchStatus=ANY&pageSize=100&page=0&textSearch=commercial1&sortOrder=DESC`
        }else if(this.user.email == 'tenant2@rakaz.com'){
          redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/alarm/DEVICE/1e12c490-cf6f-11ee-945d-6dd2594dabd4?pageSize=100&searchStatus=ANY&page=0&textSearch=commercial2&sortOrder=DESC`
        }     
        const URL = redirect_uri
        
        this.httpClient.get(URL,{ headers: httpOptions }).subscribe((data: any) => {
          data.data.forEach(ele =>{
            if(ele.details && (ele.details.temp_1 || ele.details.cov_1 || ele.details['pm2.5_1'] || ele.details['pH1'] || ele.details['hum_1'] || ele.details['conductivity1'])){
              ele.building = 'Commercial_1'
            }else{
              ele.building = 'Commercial_2'
            }
        
          })
          this.notifications=data.data;
        //   this.pagination.length = data.totalElements
         
        //   this.loadingapi=false 
          this._changeDetectorRef.markForCheck();
          }, error =>{
            console.log("error", error)
          })
      }

      async get_ro_overview_data(){    
        const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders({
          'X-Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        });
  
        var tokendetails = JSON.parse(localStorage.getItem('tokendetails'))
        var redirect_uri= "https://iomldevtb.mydigitalpetro.com/api/tenant/deviceInfos?pageSize=50&page=0&type=Commercial&sortProperty=name&sortOrder=DESC"
        var customerid=""
        if(tokendetails && tokendetails.sub != 'tenant@thingsboard.org'){
                  customerid = tokendetails.customerId
          redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/customer/${tokendetails.customerId}/deviceInfos?pageSize=10&page=0&sortProperty=createdTime&sortOrder=DESC&deviceProfileId=`
        }
    
        const URL = redirect_uri
        
        this.httpClient.get(URL,{ headers: httpOptions }).subscribe(async (data: any) => {
            this.ro_overviewdata=[]
           
              data.data.forEach(ele =>{
                if((tokendetails.sub.includes('customer') && tokendetails.firstName == ele.id.id) || !tokendetails.sub.includes('customer')){
                this.ro_overviewdata.push({
                  name:ele.name,
                  device_id:ele.id.id,
                  popupdata:`<b>${ele.name} (${ele.customerTitle})</b><br><br>`,
                  latitude:"",
                  longitude:"",
                  tenant:ele.customerTitle,
                  hostdata:[],
                  classdata: "text-5xl text-sky-600 font-semibold leading-none tracking-tight",
                  classtenant: "mt-1 text-lg text-sky-600 font-medium text-center",
                  classouter: "col-span-2 sm:col-span-1 flex flex-col items-center justify-center py-8 px-1 shadow-2xl rounded-2xl bg-sky-100 text-secondary dark:bg-white dark:bg-opacity-5"
                })}
              })
            console.log("rodata",this.ro_overviewdata)
            data = await this.getopenalerts()
            data = await this.get_device_Status()
            // data = await this.getalerts()
            this.mapdatatoshow = this.ro_overviewdata
            // this.ref_ro_overviewdata = JSON.parse(JSON.stringify(this.ro_overviewdata))
          }, error =>{
            console.log("error", error)
          })
      }
  

      async getopenalerts() {
        const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders({
          'X-Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        });
      //  var final =  await Promise.all(this.ro_overviewdata.map(async ele => {
        var redirect_uri = `https://iomldevtb.mydigitalpetro.com:443/api/alarms?searchStatus=ACTIVE&pageSize=10000&page=0&textSearch=Commercial%20Alarm&sortProperty=type&sortOrder=DESC`
          // var redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/plugins/telemetry/DEVICE/${ele.device_id}/values/attributes/SERVER_SCOPE`
          const URL = redirect_uri;
  
          try {
            this.notifications = await this.httpClient.get(URL, { headers: httpOptions }).toPromise();
            // <b>Marker 1</b><br>This is a marker with some details.
            this.notifications.data.forEach(elem =>{
              this.ro_overviewdata.forEach(building=>{
                if(building.device_id == elem.originator.id){
                  elem.building = building.name
                }
                if(elem.customerId.id == 'e7aa3c60-d790-11ee-945d-6dd2594dabd4'){
                  elem.tenant = 'Tenant2'
                }
                if(elem.customerId.id == '7c3589d0-d790-11ee-945d-6dd2594dabd4'){
                  elem.tenant = 'Tenant1'
                }
                if(elem.customerId.id == '0c1a1610-d791-11ee-945d-6dd2594dabd4'){
                  elem.tenant = 'Tenant3'
                }
                if(elem.customerId.id == '60040510-d791-11ee-945d-6dd2594dabd4'){
                  elem.tenant = 'Tenant4'
                }
                if(elem.customerId.id == '16a81eb0-e7a9-11ee-945d-6dd2594dabd4'){
                  elem.tenant = 'Tenant5'
                }
  
              })
            })
            
            console.log("result data alerts", this.notifications)
            this._changeDetectorRef.detectChanges();
            // this.alertsref = JSON.parse(JSON.stringify(this.alerts))
          } catch (error) {
            console.log("error", error);
          }
        // })
        // );
        // console.log("result data device status", this.ro_overviewdata)
       
      }


      async get_device_Status() {
        const ro = Number(localStorage.getItem('loggedinro'))
        const httpOptions = new HttpHeaders({
          'X-Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        });
      
       var final =  await Promise.all(this.ro_overviewdata.map(async ele => {
          var redirect_uri = `https://iomldevtb.mydigitalpetro.com/api/plugins/telemetry/DEVICE/${ele.device_id}/values/attributes/SERVER_SCOPE`
          const URL = redirect_uri;
  
          try {
            const data: any = await this.httpClient.get(URL, { headers: httpOptions }).toPromise();
            // <b>Marker 1</b><br>This is a marker with some details.
            data.forEach((elem,i) =>{
              if(elem.key == 'active' && elem.value){
                ele.status = 'Active'
              }
             
              
              if(elem.key == 'lastActivityTime'){
                ele.activetime = this.datePipe.transform(new Date(elem.value), 'dd-MM-yyyy hh:mm a')
              }
              if(elem.key == 'active' && !elem.value){
                ele.status = 'Inactive'
               
              }
              if(i == data.length-1 && ele.status == 'Inactive'){
                this.notifications.data.push({
                  "building":ele.name,
                  "details":{
                    "alert":"Device Inactive",
                    "time":ele.activetime
                  }
                })
              }
            })
            
            console.log("result data", data)
            this._changeDetectorRef.detectChanges();
          } catch (error) {
            console.log("error", error);
          }
        })
        );
        console.log("result data device status", this.ro_overviewdata)
       
      }
}
