/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    /*{
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },*/
    // {
    //     id   : 'project',
    //     title: 'Project',
    //     type : 'basic',
    //     icon : 'heroicons_outline:desktop-computer',
    //     link : '/project'
    // }
    // {
    //     id   : 'overview',
    //     title: 'Overview',
    //     type : 'basic',
    //     icon : 'show_chart',
    //     link : '/overview'
    // },
    {
        id   : 'traffic-tech',
        title: 'Overview',
        type : 'basic',
        icon : 'show_chart',
        link : '/traffic-tech'
    },
    // {
    //     id: 'Pumps',
    //     title:"Pump",
    //     type:'basic',
    //     icon:'sensors',
    //     link:'/health-status'
    // },
    {
        id   : 'dashboard',
        title: 'Reports',
        type : 'basic',
        icon : 'dashboard',
        link : '/building-dashboard'
    },
    {
        id   : 'alerts',
        title: 'Alerts',
        type : 'basic',
        icon : 'feather:alert-triangle',
        link : '/alerts'
    },
    {
        id   : 'configurations',
        title: 'Configurations',
        type : 'basic',
        icon : 'graphic_eq',
        link : '/configurations'
    },
    {
        id   : 'hmi',
        title: 'HMI',
        type : 'collapsable',
        icon : 'heroicons_outline:chart-pie',
        children:[
            {
                id:'vortex',
                title:'Vortex',
                icon:'show_charts',
                link:'/vortex/vortex',
                type:'basic'
            },
            {
                id:'mechanical',
                title:'Mechanical',
                icon:'show_charts',
                link:'/vortex/mechanical',
                type:'basic'
            },
            {
                id:'odor',
                title:'Odour Control Unit',
                icon:'show_charts',
                link:'/vortex/odour-control-unit',
                type:'basic'
            },
            {
                id:'tdf',
                title:'TDF-Pump',
                icon:'show_charts',
                link:'/vortex/tdf-pump',
                type:'basic'
            },

        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
